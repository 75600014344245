import React, { useState } from "react";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { config } from "../../helpers/config";
import MainMenu from "./main-menu";
import { Link } from "react-router-dom";
import "./menubar.scss";
import BackgroundLogo from "./background-logo";
import { FaWhatsapp } from "react-icons/fa";

const Menubar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => setShowMenu(false);

  return (
    <Navbar style={{ background: "black" }} expand="lg" sticky="top">
    <Container>
      <Navbar.Brand as={Link} to="/" className="d-flex text-center">
        <Image
          style={{ width: "55px" }}
          src="/images/logo/logo.jpg"
          alt={config.project.name}
        />
      </Navbar.Brand>
      <div className="dracar"
        style={{
          textAlign: "center",
          
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            
          }}
        >
         DRACAR GRUP SAĞLIK TURİZM <br /> LİMİTED ŞTİ.
        </div>
        
      </div>
      <Navbar.Toggle
        aria-controls="mainMenu"
        style={{ backgroundColor: "#ddac43" }}
        onClick={() => setShowMenu(!showMenu)}
      />

      <Navbar.Offcanvas
        id="mainMenu"
        aria-labelledby="offcanvas"
        placement="start"
        show={showMenu}
        onHide={handleMenuClose}
        style={{ color: "#ddac43", backgroundColor: "black" }}
      >
        <Offcanvas.Header closeButton>
          <BackgroundLogo/>
          <Offcanvas.Title id="offcanvas">
            <Image
              style={{ width: "95px", paddingLeft: "10px" }}
              src="/images/logo/logo.jpg"
              alt={config.project.name}
            />
            <div
              style={{
                fontSize: "18px",
                fontWeight: "700",
                marginTop: "10px",
                color: "#c9c6c6",
                marginLeft: "10px",
              }}
            >
             DRACAR GRUP SAĞLIK TURİZM LİMİTED ŞTİ.
            </div>
            
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <MainMenu
            className="mainmenu justify-content-center flex-grow-1 pe-3"
            onClick={handleMenuClose}
          />

          <div
            className="btn btn-outline-secondary"
            style={{ marginLeft: "30px", paddingTop: "12px"}}
          >
            <Nav.Link as={Link} to="/iletisim" onClick={handleMenuClose}>
                BİZE ULAŞIN <br /> 0531 232 50 00 <FaWhatsapp style={{color:"green", justifyContent:"center", marginTop:"-4px" }} />
              </Nav.Link>
          </div>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
  );
};

export default Menubar;
