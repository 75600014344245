import React from "react";
import { config } from "../../helpers/config";
import "./map.scss";

const Map = () => {
  return (
    <div className=" text-center mt-5">
    <iframe
      src={config.contact.mapEmbedURL}
      width="600"
      height="350"
      style={{ border: 0 }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      title={config.contact.address}
      className="map"
    ></iframe>
  </div>
  );
};

export default Map;


