import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { config } from "../../helpers/config";

import "./footer.scss";
import SocialMenu from "./social-menu";
import ContactMenu from "./contact-menu";


const Footer = () => {
  return (
    <footer style={{backgroundImage:"url(/images/logo/arka-plan-logo-1.jpg)", backgroundSize:"cover", backgroundRepeat:"no-repeat"}}>
      <Container>
        <Row className="g-5">
          <Col lg={4}>
            <Link to="/">
              <Image
              style={{width:"80px"}}
                src="/images/logo/logo.jpg"
                alt={config.project.name}
              />
            </Link>
            <p style={{paddingTop:"10px"}}>{config.project.description}</p>
          </Col>
         
          <Col xs={12} sm={6} md={6} lg={4}>
            <h3 style={{paddingLeft:"10px"}}>SOSYAL MEDYA</h3>
            <SocialMenu className="flex-column" />
          </Col>
          <Col md={6} lg={4}>
            <h3 style={{paddingLeft:"10px"}}>İLETİŞİM</h3>
            <ContactMenu  className="flex-column" />
          </Col>
        </Row>
       <p className="text-center" style={{marginTop:"90px", fontWeight:"bold", fontSize:"18px"}} > © 2025 <a  href="https://api.whatsapp.com/send?phone=905384182747">Dracar Grup Sağlık Turizm Limited Şti. </a> | Tüm Hakları Saklıdır.</p>
       <p className="text-center mt-3"> <a href="https://www.linkedin.com/in/yasin-ibin-9b4025277/" style={{textDecoration:"none", color:"#c9c6c6", fontSize:"16px"}}>Designed by Yasin İBİN</a></p>
      </Container>
      
    </footer>
  );
};

export default Footer;
