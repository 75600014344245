export const config = {
    project: {
      name: "Dracar Grup Sağlık Turizm Limited Şti.",
      slogan:
        "Sağlığınıza Özel Cerrahi Çözümler",
      description:
        " Sağlığınızın her aşamasında yanınızda olan ekibimiz, kişiye özel cerrahi çözümlerle hizmet vermektedir.",
      version: "1.0.0",
    },
    contact: {
      phone1: "+90 (531) 232 50 00",
      phone2: "+90 (531) 232 50 00",
      email: "altaynihatacar@hotmail.com",
      address: "15 Temmuz Mahallesi Alparslan Türkeş Caddesi No:66/B İç Kapı No:2 ŞEHİTKAMİL/GAZİANTEP Primemall Avm Karşısı Sarı Villalar",
    mapURL: "https://maps.app.goo.gl/VgARfboyC6F1D2GTA",
      mapEmbedURL:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d398.0242630077405!2d37.32144064406985!3d37.052857379053584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1531e1c2ded81ae3%3A0x5a0dafad42fb1f5!2sOp.%20Dr.%20Ayfer%20%C5%9Een%20Acar!5e0!3m2!1str!2str!4v1735589505003!5m2!1str!2str",
      socialMedia: {
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        instagram: "https://www.instagram.com/op.dr.altay.nihat.acar/",
        youtube: "https://youtube.com",
      },
    },
    api: {
      baseUrl: "https://dracar.com/api",
    },
   
  };
  
  
  